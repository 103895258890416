require('jquery')
require('angular')
require('angular-animate')
require('angular-aria')
require('angular-i18n/angular-locale_fr-fr')
require('angular-material')
require('angular-messages')
require('angular-message-format')
require('angular-route')
require('angular-sanitize')
require('angular-file-upload')
require('angularjs-rails-resource')
require('legacy/angular-remove-diacritics')
require('core-js/features/array')
require('core-js/features/object')
require('core-js/features/function')
require('core-js/features/string')
require('core-js/features/number')
require('regenerator-runtime/runtime')
